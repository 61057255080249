<template>
	<div class="app-container">
		<el-header class="new-header">
			<div class="new-header-left">销售客户公海</div>
		</el-header>
		<el-main>
			<el-form ref="searchForm" :inline="true" :model="searchForm">
				<el-form-item label="姓名：">
					<el-input
						v-model="searchForm.customerName"
						placeholder="姓名"
						size="small"
					></el-input>
				</el-form-item>
				<el-form-item label="手机号:">
					<el-input
						v-model="searchForm.customerPhone"
						placeholder="手机号"
						size="small"
					></el-input>
				</el-form-item>
				<el-form-item label="公司名称:">
					<el-input
						v-model="searchForm.companyName"
						placeholder="公司名称"
						size="small"
					></el-input>
				</el-form-item>
				<el-form-item label="销售:">
					<el-select
						v-model="searchForm.salesId"
						placeholder="销售"
						size="small"
						clearable
						filterable
						@clear="searchForm.salesId = null"
					>
						<el-option
							v-for="item in sales"
							:key="item.userId"
							:label="item.userName"
							:value="item.userId"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="创建时间:">
					<el-date-picker
						v-model="dateRange"
						type="datetimerange"
						size="small"
						value-format="yyyy-MM-dd HH:mm:ss"
						range-separator="-"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						@change="handleDataRangeChange"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="客户状态:">
					<el-select
						v-model="searchForm.customerState"
						placeholder="客户状态"
						size="small"
						clearable
						filterable
						@clear="searchForm.customerState = null"
					>
						<el-option
							v-for="item in customerStateList"
							:key="item.id"
							:label="item.value"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标签:">
					<el-select
						v-model="searchForm.customerType"
						placeholder="标签"
						size="small"
						clearable
						filterable
						@clear="searchForm.customerType = null"
					>
						<el-option
							v-for="item in customerTypeList"
							:key="item.id"
							:label="item.value"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit" size="small"
						>搜索</el-button
					>
          <el-button
              v-if="hasDeleteButton"
              type="primary"
              @click="handleBatchDeleteCustomer"
              size="small"
          >批量删除
          </el-button>
          <el-button
              type="primary"
              @click="handleBatchPullMyCustomer"
              size="small"
          >批量领取
          </el-button>
				</el-form-item>
			</el-form>
			<el-table
				:data="list"
				v-loading="loading"
				stripe
				style="width: 100%"
				border
        @selection-change="handleSelectionChange"
			>
        <el-table-column
            type="selection"
            width="50"
            align="center"
        ></el-table-column>
				<el-table-column type="index" width="50" label="编号" align="center">
				</el-table-column>
				<el-table-column
					prop="customerName"
					label="客户姓名"
					align="center"
				></el-table-column>
				<el-table-column prop="customerPhone" label="联系电话" align="center">
				</el-table-column>
				<el-table-column
					prop="companyName"
					label="公司名称"
					align="center"
				></el-table-column>
        <el-table-column label="地址"  align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{ scope.row.address }}</span>
          </template>
        </el-table-column>
				<el-table-column
					prop="industry"
					label="行业"
					align="center"
				></el-table-column>
				<el-table-column prop="customerTypeName" align="center" label="标签">
				</el-table-column>
				<el-table-column
					prop="customerStateName"
					align="center"
					label="客户状态"
				>
				</el-table-column>
				<el-table-column
					prop="createTime"
					align="center"
					label="创建时间"
				></el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<el-button
							@click="handleClickMyReceiveCustomer(scope.row)"
							type="text"
							size="small"
							>领取</el-button
						>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-block">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageVo.current"
					:page-sizes="[10, 20, 50, 100, 500]"
					:page-size="pageVo.size"
					layout="total, sizes, prev, pager, next, jumper"
					:total="totalCount"
				>
				</el-pagination>
			</div>
		</el-main>
	</div>
</template>
<script>
import {
  batchCustomerDelete,
  batchMyReceiveCustomer,
  getSaleCustomerHighSeasList,
  getUserListOfMerchantByPage,
  initCustomerInfo,
  myReceiveCustomer,
} from '../../api'
	export default {
		data() {
			return {
        hasAdminRole: this.$store.getters.role.includes('admin'),
        hasDirectorRole: this.$store.getters.role.includes('director'),
        hasDeleteButton: false,
        loading: false,
				list: [],
				userId: this.$store.getters.userId,
				dialogForAssignment: false,
				dialogForFollow: false,
				assignmentForm: {
					salesId: null,
					customerIdList: [],
				},
				searchForm: {
					userId: this.$store.getters.userId,
					customerName: '',
					customerPhone: '',
					companyName: '',
					salesId: null,
					customerState: null,
					customerType: null,
					startTime: '',
					endTime: '',
				},
				dateRange: [],
				pageVo: {
					current: 1,
					size: 10,
				},
				totalCount: 0,
				customerTypeList: [],
				customerStateList: [],
				sales: [],
				selectedCustomers: [],
				CustomerFollowTypeList: [],
				followInfoForm: {
					followType: null,
					content: null,
					customerId: null,
				},
				rules: {
					followType: [
						{ required: true, message: '请选择跟进类型', trigger: 'change' },
					],
					content: [
						{ required: true, message: '请输入跟进内容', trigger: 'blur' },
					],
				},
			}
		},
		created() {
			this.initSelectOptions()
			this.loadCustomerList()
      this.hasDeleteButton = this.hasAdminRole || this.hasDirectorRole
		},
		methods: {
      handleSelectionChange(val) {
        this.selectedCustomers = val
      },
      handleBatchDeleteCustomer() {
        if (this.selectedCustomers.length) {
          this.$confirm(
              `确认删除选择的${this.selectedCustomers.length}条客户信息吗?`,
              '批量删除',
              {
                type: 'warning',
                showCancelButton: true,
                cancelButtonText: '取消',
                confirmButtonText: '确认',
              }
          )
              .then(() => {
                let param = {
                  userId: this.userId,
                  customerIdList: this.selectedCustomers.map(
                      (item) => item.customerId
                  ),
                }
                batchCustomerDelete(param).then((data) => {
                  if (data) {
                    this.$notify({
                      title: '成功',
                      message: '批量删除客户成功',
                      type: 'success',
                    })
                    this.loadCustomerList()
                  }
                })
              })
              .catch((e) => {
                console.log(e)
              })
        } else {
          this.$message.error('请选择客户后操作')
        }
      },
      handleBatchPullMyCustomer() {
        if (this.selectedCustomers.length) {
          this.$confirm(
              `确认领取选择的${this.selectedCustomers.length}条客户信息吗?`,
              '批量领取',
              {
                type: 'warning',
                showCancelButton: true,
                cancelButtonText: '取消',
                confirmButtonText: '确认',
              }
          )
              .then(() => {
                let param = {
                  userId: this.userId,
                  customerIdList: this.selectedCustomers.map(
                      (item) => item.customerId
                  ),
                }
                batchMyReceiveCustomer(param).then((data) => {
                  if (data) {
                    this.$notify({
                      title: '成功',
                      message: '批量领取客户成功',
                      type: 'success',
                    })
                    this.loadCustomerList()
                  }
                })
              })
              .catch((e) => {
                console.log(e)
              })
        } else {
          this.$message.error('请选择客户后操作')
        }
      },
			async initSelectOptions() {
				let data = await initCustomerInfo()
				this.customerStateList = data.customerStateList
				this.customerTypeList = data.customerTypeList
				let sales = await getUserListOfMerchantByPage({
					current: 1,
					size: 500,
					params: { userId: this.userId },
				})
				this.sales = sales.records
			},
			handleDataRangeChange(value) {
				//时间选择器触发事件
				if (value) {
					this.searchForm.startTime = value[0]
					this.searchForm.endTime = value[1]
				} else {
					this.searchForm.startTime = ''
					this.searchForm.endTime = ''
				}
			},
			onSubmit() {
				this.pageVo.current = 1
				this.loadCustomerList()
			},
			handleSizeChange(val) {
				this.pageVo.size = val
				this.loadCustomerList()
			},
			handleCurrentChange(val) {
				this.pageVo.current = val
				this.loadCustomerList()
			},
			loadCustomerList() {
				let params = {
					...this.pageVo,
					params: this.searchForm,
				}
				this.loading = true
				getSaleCustomerHighSeasList(params).then((data) => {
					this.list = data.records
					this.totalCount = data.total
					this.loading = false
				})
			},
			handleClickMyReceiveCustomer(row) {
				this.$confirm(`确认领取客户${row.customerName}吗?`, '领取客户', {
					type: 'success',
					showCancelButton: true,
					cancelButtonText: '取消',
					confirmButtonText: '确认',
				})
					.then(() => {
						myReceiveCustomer({ customerId: row.customerId }).then((data) => {
							if (data) {
								this.$notify({
									title: '成功',
									message: '领取客户成功!',
									type: 'success',
								})
								this.loadCustomerList()
							}
						})
					})
					.catch(() => {})
			},
		},
	}
</script>
